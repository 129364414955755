import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from '../_reducers';
import { compose } from 'redux'; // and your other imports from before

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = process.env.NODE_ENV === 'development' ?
    createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
    : createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

import { apiService } from "./api.service"

export const accountService = {
    getAccount,
    topUp,
    verifyTransaction,
    getTransactions,
    getPublicKey,
    updateAccount
}
function getAccount(id) {
    apiService.getItem(`account/${id}`)
}
function topUp(amount) {
    return apiService.addItem('account/topup', amount)
}
function getPublicKey() {
    return apiService.getItems(`account/publicKey`)
}
function getTransactions(accountId, page, count, pending) {
    var options = { searchField: "accountId", page: page, pageSize: count, searchValue: accountId, sortBy: "timeStamp", sortDirection: "descending" };
    if (pending) {
        options.searchField = undefined;
        options.searchFields = ['accountId', 'status'];
        options.searchValue = undefined;
        options.searchValues = [accountId, '0']
    }
    return apiService.getItems(`transaction`, options);
}

function verifyTransaction(id) {
    return apiService.getItem(`transaction/verify`, id)
}

function updateAccount(account) {
    return apiService.updateItem('account', account);
}
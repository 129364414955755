import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import '../../../Styles/dashboard.scss';
import { apiService } from "../../../_services/api.service";
import { assetsService } from '../../../_services';
import { useTranslation } from 'react-i18next';
import { journeyService } from "../../../_services/journeys.service";

export default function PerformanceTrackerCard() {
    const [view, setView] = useState('rating');
    const [userRatings, setUserRatings] = useState([]);
    const [ratings, setRatings] = useState(0); // Initialize as 0
    const [responseAvr, setResponseAvr] = useState(0);
    const [overallSatisfaction, setOverallSatisfaction] = useState(0);
    const user = useSelector(state => state.authentication.user);
    const currentUserId = useSelector(state => state.authentication.user.id);
    const currentUserName = useSelector(state => state.authentication.user.name);
    const [asset, setAsset] = useState();
    const { t } = useTranslation("common");

    useEffect(() => {
        if (user) {
            getAllUserRatings();
            getPhoto(user.assetId);
            getSurvey();
            getResponse();
        }
    }, [user]);

    useEffect(() => {
        // Calculate overall satisfaction when both ratings and responseAvr are updated
        setOverallSatisfaction((ratings + responseAvr) / 2);
    }, [ratings, responseAvr]);

    async function getAllUserRatings() {
        const allUsers = await apiService.getItems('users', { pageSize: 1000 });

        const usersWithSatisfaction = await Promise.all(allUsers.items.map(async user => {
            const survey = await getSurvey(user.id); // This remains unchanged
            const response = await getResponse(user.id); // Filtered by the current month

            const overallSatisfaction = ((survey + response) / 2).toFixed(2);

            const photoContent = await getAssetContent(user.assetId);

            return {
                id: user.id,
                name: user.name,
                overallSatisfaction: parseFloat(overallSatisfaction),
                photoContent: photoContent,
            };
        }));

        const sortedUsers = usersWithSatisfaction.sort((a, b) => b.overallSatisfaction - a.overallSatisfaction);

        setUserRatings(sortedUsers);
    }




    async function getSurvey(userId = currentUserId) {
        const survey = await apiService.getItems('survey', { searchFields: ['questionId', 'userId'], searchValues: [102, userId] });
        const rating = survey.items.reduce((accumulator, currentValue) => accumulator + currentValue.rating, 0);
        if (userId === currentUserId) {
            setRatings(rating / survey.items.length || 0); // Ensure it is a valid number
        }
        return rating / survey.items.length || 0;
    }

    async function getResponse(userId = currentUserId) {
        const currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-11
        const currentYear = new Date().getFullYear();

        const options = {
            searchFields: ['staff.userId'],
            searchValues: [userId],
            pageSize: 1000,
            include: ["videocall.participants", "staff"]
        };

        const response = await journeyService.getAppointments(options);
        const appointments = response.items.filter(appointment => {
            const appointmentMonth = new Date(appointment.date).getMonth() + 1; // Ensure date field matches your API response
            const appointmentYear = new Date(appointment.date).getFullYear();
            return appointmentMonth === currentMonth && appointmentYear === currentYear;
        });

        let unattendedBySalesperson = 0;
        let bothJoined = 0;
        let customerNoShow = 0;

        for (const app of appointments) {
            if (app.videoCall) {
                const customerJoined = app.videoCall.participants.some(p => p.customerId && p.startTime);
                const salespersonJoined = app.videoCall.participants.some(p => p.userId === userId && p.startTime);

                if (customerJoined && !salespersonJoined) {
                    unattendedBySalesperson++;
                } else if (customerJoined && salespersonJoined) {
                    bothJoined++;
                } else if (!customerJoined && salespersonJoined) {
                    customerNoShow++;
                }
            }
        }

        const totalRelevantAppointments = unattendedBySalesperson + bothJoined + customerNoShow;
        const totalScore = totalRelevantAppointments ? ((customerNoShow + bothJoined) / totalRelevantAppointments) * 5 : 0;

        if (userId === currentUserId) {
            setResponseAvr(parseFloat(totalScore.toFixed(2)) || 0);
        }
        return totalScore;
    }


    const getAssetContent = async (assetId) => {
        if (assetId) {
            const response = await assetsService.getAssetById(assetId);
            return response ? response.content : null; // Return asset content if available
        }
        return null;
    };

    const getPhoto = async (id) => {
        if (id) {
            const response = await assetsService.getAssetById(id);
            if (response) {
                setAsset(response);
            }
        }
    }

    const filledStars = Math.floor(overallSatisfaction);
    const halfStar = overallSatisfaction % 1 >= 0.5;

    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= filledStars) {
                stars.push(<i key={i} className="fas fa-star filled"></i>); // filled star
            } else if (i === filledStars + 1 && halfStar) {
                stars.push(<i key={i} className="fas fa-star-half-alt filled"></i>); // half-filled star
            } else {
                stars.push(<i key={i} className="far fa-star"></i>); // empty star
            }
        }
        return stars;
    };

    const maxScore = 5;
    const customerFeedbackPercentage = (ratings / maxScore) * 100;
    const responseAvrPercentage = (responseAvr / maxScore) * 100;
    const overallSatisfactionPercentage = (overallSatisfaction / maxScore) * 100;

    return (
        <div className='card-holder h-100'>
            <Card className='dashboard-appointments-card h-100'>
                <div className='dashboard-card-sm-title'>{t("dashboard.performance-tracker")}</div>
                <div className='dashboard-card-tags-wrap'>
                    <div className={`dashboard-card-tag ${view === 'rating' ? 'active' : ''}`} onClick={() => setView('rating')}>
                        {t("dashboard.rating")}
                    </div>
                    <div className={`dashboard-card-tag ${view === 'ranking' ? 'active' : ''}`} onClick={() => setView('ranking')}>
                        {t("dashboard.ranking")}
                    </div>
                </div>
                <Card.Body>
                    {view === 'rating' ? (
                        <div className='rating-card-wrap'>
                            <div className='rating-card'>
                                <div className='rating-value'>
                                    <span className='number'>{overallSatisfaction.toFixed(2)}</span>
                                    <span className='stars'>
                                        {renderStars()}
                                    </span>
                                </div>
                            </div>
                            <div className='rating-communication-value'>
                                <div className='rating-communication-title'>
                                    <span>Customer Feedback</span>
                                    <span>{ratings.toFixed(2)}</span>
                                </div>
                                <div className='rating-response-wrap'>
                                    <span className='rating-response-foreground' style={{ width: `${customerFeedbackPercentage}%` }} ></span>
                                    <span className='rating-communication-background'></span>
                                </div>
                            </div>
                            <div className='rating-response-value'>
                                <div className='rating-communication-title'>
                                    <span>Response Average</span>
                                    <span>{responseAvr.toFixed(2)}</span>
                                </div>
                                <div className='rating-response-wrap'>
                                    <span className='rating-communication-foreground' style={{ width: `${responseAvrPercentage}%` }}></span>
                                    <span className='rating-communication-background'></span>
                                </div>
                            </div>
                            <div className='rating-overall-value'>
                                <div className='rating-communication-title'>
                                    <span>Overall Satisfaction</span>
                                    <span>{overallSatisfaction.toFixed(2)}</span>
                                </div>
                                <div className='rating-response-wrap'>
                                    <span className='rating-overall-foreground' style={{ width: `${overallSatisfactionPercentage}%` }}></span>
                                    <span className='rating-communication-background'></span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='ranking-user-list'>
                            {userRatings.map((user, index) => (
                                <div className={user.id === currentUserId ? "ranking-user self-user" : "ranking-user"} key={user.id}>
                                    <span className='ranking-number'>#{index + 1}</span>
                                    <span className='ranking-user-wrap'>
                                        <span className='ranking-user-image'>
                                            {user.photoContent && user.id === currentUserId ? <img src={user.photoContent} className="ranking-profile-picture" />
                                                : <i className="fas fa-user-circle"></i>}
                                        </span>
                                        <span className='ranking-user-name'>{user.id === currentUserId ? user.name : "Anonymous"}</span>
                                        <span className='ranking-user-rating'>
                                            <i className='fas fa-star'></i>
                                            {user.overallSatisfaction.toFixed(2)}
                                        </span>
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import '../Account/_account-page.scss';
import '../../Styles/dashboard.scss';
import DashboardHeader from './DashboardComponents/DashboardHeader';
import { getRole } from '../../_helpers/user.perms';
import JourneyPendingOrders from './DashboardComponents/JourneyPendingOrders';
import TodaysAppointmentsCard from './DashboardComponents/TodaysAppointment';
import PerformanceTrackerCard from './DashboardComponents/PerformanceTracker';
import "react-toggle/style.css"
import { DashboardAvailability } from './DashboardAvailability';

export default function Dashboard() {
    const user = useSelector(state => state.authentication.user);
    const [role, setRole] = useState();



    useEffect(() => {
        setRole(getRole(user));
    }, []);

    function CardTitle(props) {
        return <div className="card-subtitle">{props.children}</div>
    }

    return (
        <div className="dashboard-container">
            <div className='row'>
                <div className='col-6'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <DashboardHeader role={role} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-6'><DashboardAvailability user={user} /></div>
            </div>
            <div className='col-12 row'>
                <div className='col-4'>
                    <PerformanceTrackerCard CardTitle={CardTitle} />
                </div>
                <div className='col-4'>
                    <JourneyPendingOrders CartTitle={CardTitle} />
                </div>
                <div className='col-4'>
                    <TodaysAppointmentsCard CardTitle={CardTitle} />
                </div>
            </div>
        </div>
    );
}

import { alertActions, userActions } from "../_actions";
import { store } from "./store";


export function authHeader() {
    // return authorization header with jwt token
    let user = undefined;
    try {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            user = JSON.parse(storedUser);
        }
    } catch (e) {
        console.log("Unabled to read user from storage", e)
    }

    if (user && user.token) {
        return { 'x-authorization-token': user.token };
    } else {
        return {};
    }
}

const defaultErrorObject = {
    404: '404 Error: Sorry, not found',
    405: '405 Error: Bad request',
    409: '409 Error: Item in use',
    500: '500 Error: Internal server error',
    503: '503 Error: Service Unavailable'
}

export function handleResponse(response, errorHandled, options) {
    const errorObject = { ...defaultErrorObject, ...(options ? options : {}) }

    return response.text().then(text => {
        if (response.status == 204)
            return null;
        let data = text;
        if (text && text.length) {
            try {
                data = JSON.parse(text);
            }
            catch {
                //Swallow it
            }
        }
        if (!response.ok) {
            const error = {
                message: data.message || data || response.statusText,
                status: response.status
            };

            if (!errorHandled) {

                return Promise.reject(error).catch(() => {
                    const status = response.status
                    if (status) {
                        return store.dispatch(status === 401 ? userActions.silentLogout() : alertActions.error(errorObject[status] || ""))
                    }
                });
            } else {
                return Promise.reject(error)
            }
        }

        return data;
    });
}


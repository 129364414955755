import { DateTime } from "luxon";

export function formatPrice(price, currency) {
    try {
        if (currency && currency !== 'null') {
            const formattedPrice = new Intl.NumberFormat(window.navigator.languages[0], { style: 'currency', currency: currency }).format(price);
            return formattedPrice;
        }
        if (isFinite(price)) {
            return new Intl.NumberFormat(window.navigator.languages[0], { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price);
        }
    }
    catch (e) {
        console.log(price, currency, e);
    }
    return price
}

export function parseISODate(dateString) {
    var dateParts = dateString.split(/\D+/);
    const milliseconds = dateParts.length > 6 ? dateParts[6].substring(0, 3) : 0;
    const utcTimeStamp = Date.UTC(dateParts[0], --dateParts[1], dateParts[2], dateParts[3], dateParts[4], dateParts[5], milliseconds);
    return new Date(utcTimeStamp);
}

export function formatDateAndTime(date) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return formatDate(date, options)
}
export function formatDateTextAndTime(date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return formatDate(date, options)
}

export function formatISOTime(date) {
    return parseISODate(date).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })
}
export function formatTime(date) {
    return date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })
}

export function formatDate(date, options) {
    if (!options)
        options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(window.navigator.languages[0], options);
}

export function readTimeSpan(timespan) {
    const parts = timespan.split(':');
    return {
        hour: parseInt(parts[0]),
        minute: parseInt(parts[1]),
        second: parseInt(parts[2]),
    }
}

export function minutesToTimeSpan(minutes) {
    if (minutes) {
        const hours = Math.floor(minutes / 60);
        const min = minutes - (hours * 60);
        return `${hours}:${min}:00`;
    }
    else return "00:00:00";
}

export function timeToDateTime(time) {
    const result = DateTime.fromISO(time);
    return formatTime(result.toJSDate());
}

export function timeSpanToMinutes(timespan) {
    if (!timespan)
        return 0;
    const duration = readTimeSpan(timespan);
    return duration.hours * 60 + duration.minutes + duration.seconds / 60.0;
}

export function timeSpanToDuration(timeSpan) {
    const { hour, minute, second } = readTimeSpan(timeSpan)

    let result = "";
    function addTimeChunk(value, label) {
        if (value == 1) {
            return `${parseInt(value)}\u00A0${label}`
        } else if (value > 1) {
            return `${parseInt(value)}\u00A0${label}s`
        }
    }

    if (hour > 0)
        result += addTimeChunk(hour, "hour");
    if (minute > 0) {
        if (result)
            result += " ";
        result += addTimeChunk(minute, "minute");
    }
    if (second > 0) {
        if (result)
            result += " ";
        result += addTimeChunk(second, "second");
    }
    if (!result)
        return "0 minutes"

    return result;
}

export function minutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    let result = '';
    if (hours > 0) {
        result += `${hours} hour${hours > 1 ? 's' : ''}`;
    }
    if (remainingMinutes > 0) {
        if (result) result += ' ';
        result += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
    }

    return result;
}

export function formatDateWithTimeSpan(date, timeSpan) {
    switch (timeSpan) {
        case "1d":
            return new Date(Number(date)).toLocaleString(window.navigator.languages[0], {
                hour: "numeric",
            });
        case "1w":
            return new Date(Number(date)).toLocaleString(window.navigator.languages[0], {
                weekday: "short",
            });
        case "1m":
            return new Date(Number(date)).toLocaleString(window.navigator.languages[0], {
                day: "numeric",
                month: "short",
            });
        case "1y":
            return new Date(Number(date)).toLocaleString(window.navigator.languages[0], {
                month: "short",
            });
        default:
            break;
    }

}

export function createDateAsUTC(date) {
    if (typeof date === "string") {
        date = new Date(date);
    }
    if (date.getFullYear() <= 1)
        return undefined;
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    utcDate.setFullYear(date.getFullYear());
    console.log("DATE", date, "UTC DATE", utcDate);
    return utcDate;
}

export const DayOfTheWeek = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
}

export function dayOfTheWeek(day) {
    switch (day) {
        case DayOfTheWeek.Monday: return "Monday";
        case DayOfTheWeek.Tuesday: return "Tuesday";
        case DayOfTheWeek.Wednesday: return "Wednesday";
        case DayOfTheWeek.Thursday: return "Thursday";
        case DayOfTheWeek.Friday: return "Friday";
        case DayOfTheWeek.Saturday: return "Saturday";
        case DayOfTheWeek.Sunday: return "Sunday";
    }
}
export function timeBetweenDates(start, end) {
    var operateDate = Math.abs(new Date(start) - new Date(end))
    var timeBetween = Math.floor(operateDate / 1000) / 60;
    return timeBetween;
}

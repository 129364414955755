import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import '../../Styles/_components/_login.scss';
import { useTranslation } from 'react-i18next';

export function ResetForm(props) {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const auth = useSelector(state => state.authentication);

    const [inputs, setInputs] = useState({
        email: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { email } = inputs;

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function restPassword(e) {
        e.preventDefault();

        setSubmitted(true);
        if (email) {
            dispatch(userActions.resetPassword(email));
        }
    }

    return <div className="login-form">
        <div className="login-logo">
            <div className='welcome'>{t("passwordReset.request-password-reset-link")}</div>
            <div className='welcome-msg'>{t("passwordReset.request-password-reset-subtitle")}</div>
        </div>
        <form name="form" onSubmit={restPassword}>
            <div className="form-group">
                <label>{t("login.email")}</label>
                <input data-testid="email" type="text" name="email" value={email} onChange={handleChange} className={'form-control' + (submitted && !email ? ' is-invalid' : '')} />
            </div>
            <button data-testid="login" className="btn btn-primary">
                {(auth && auth.loggingIn) && <span className="spinner-border spinner-border-sm mr-1"></span>}
                {t("passwordReset.request-reset-link")}
            </button>
            <Link className='reset' onClick={() => props.setSection("login")}>{t("passwordReset.return-to-login")}</Link>
        </form></div>;
}



import React from 'react'
import { _toTitleCase } from '../../_helpers/prototypes'
export default function TextInput({ name, value, handleChange, title, submitted, disabled, important }) {
    return (
        <div className="form-group">
            {important ?
                <label>
                    <span className="text-danger">*</span>{title ?? _toTitleCase(name)}
                </label>
                :
                <label>
                    {title ?? _toTitleCase(name)}
                </label>
            }
            <input
                disabled={disabled}
                type="text"
                name={name.toString()}
                value={value}
                onChange={handleChange}
                className={'form-control' + (submitted && !name ? ' is-invalid' : '')}
                required
                pattern="\S+">
            </input>
        </div>
    )
}

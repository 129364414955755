export function generateOptions(url, options) {
    let added = url.includes("?");
    var params = "";
    if (options.searchField) {
        params += `/search?${options.searchField}=${options.searchValue}`;
        added = true;
    }
    else if (options.searchFields && options.searchFields.length > 0) {
        options.searchFields.map((_, i) => {            
            params += (added ? "&" : "/search?") + `${options.searchFields[i]}=${encodeURIComponent(options.searchValues[i])}`;
            added = true;
        })
    }
    if (options.page) {
        params += (added ? "&" : "?") + `page=${options.page}`;
        added = true;
    }
    if (options.pageSize != undefined) {
        params += (added ? "&" : "?") + `pagesize=${options.pageSize}`
        added = true;
    }
    if (options.include) {
        params += (added ? "&" : "?") + `include=${options.include.join(",")}`
        added = true;
    }
    if (options.sortBy) {
        params += (added ? "&" : "?") + `sortBy=${options.sortBy}`
        added = true;
    }
    if (options.sortDirection) {
        params += (added ? "&" : "?") + `sortDirection=${options.sortDirection}`
        added = true;
    }
    return params;
}

import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { config, page, pageToPath } from '../_constants';
import Dashboard from '../Pages/Dashboard/Dashboard';
import { State, User } from '../_types';
import { useTranslation } from 'react-i18next';

const HelpPanelControl = lazy(() => import('../HelpPages/HelpPanel'));
const FulfilmentsPage = lazy(() => import('../Pages/FulfilmentsPage'));
const CustomerPage = lazy(() => import('../Pages/CustomerPage'));
const ProductTypesPage = lazy(() => import('../Pages/ProductTypesPage'));
const LivestreamCallPage = lazy(() => import('../Pages/Livestream/LivestreamPage'));
const ShowAndSellCallPage = lazy(() => import('../Pages/Livestream/ShowAndSellCallPage'));
const CSVUploadProgress = lazy(() => import('../Components/Layout/CSVUploadProgress'));
const MediaView = lazy(() => import('../Pages/DataViews/MediaTabsView'));
const TabsView = lazy(() => import('../Pages/DataViews/TabsView'));
const CompanySettingTabsView = lazy(() => import('../Pages/DataViews/CompanySettingsTabsView'));
const AddModal = lazy(() => import('../Components/Modals/Add/AddModal'));
const ShopLocationView = lazy(() => import('../Pages/DataViews/ShopLocationView'));
const OrderView = lazy(() => import('../Pages/DataViews/OrderView'));
const AuditLogs = lazy(() => import('../Components/Table/AuditLogs'));
const AssetSourceView = lazy(() => import('../Pages/DataViews/AssetSourceView'));
const SectionTemplateView = lazy(() => import('../Pages/DataViews/SectionTemplate'));
const AlertWrapper = lazy(() => import('../Components/Layout/AlertWrapper'));
const PageHost = lazy(() => import('../Pages/Dashboard/PageHost'));
const Navheader = lazy(() => import("../Components/Layout/Navheader"));
const ProfilePage = lazy(() => import('../Pages/User/ProfilePage'));
const TenantsPage = lazy(() => import('../Pages/TenantsPage'));
const UserView = lazy(() => import('../Pages/DataViews/UserView'));
const SectionView = lazy(() => import('../Pages/DataViews/SectionView'));
const ProductView = lazy(() => import('../Pages/DataViews/ProductView'));
const JourneyView = lazy(() => import('../Pages/DataViews/JourneyView'));
const AssetView = lazy(() => import('../Pages/DataViews/AssetView'));
const UploadProgress = lazy(() => import('../Components/Layout/UploadProgress'));
const BrandView = lazy(() => import('../Pages/DataViews/BrandView'));
const ThirdPartyPage = lazy(() => import('../ThirdParty/ThirdPartyPage'));
const CallsPage = lazy(() => import('../Pages/DataViews/CallsPage'));
const ReportPage = lazy(() => import('../Pages/DataViews/ReportsTabView'));
const ReportResultsPage = lazy(() => import('../Pages/DataViews/ReportResultsPage'));
const TablePage = lazy(() => import('../Pages/DataViews/TablePage'));
const Appointments = lazy(() => import('../Pages/AppointmentsPage'));
const Messages = lazy(() => import('../Pages/Communications/CommunicationCenter'));
const EmailTemplatesPage = lazy(() => import('../Pages/EmailTemplatesPage'));

function AlreadyLoggedIn() {

    const navigate = useNavigate();

    useEffect(() => {

        function checkTenancy() {
            let result = window.location.host.replace(config.admin, "");
            return result.length > 0;
        }

        if (checkTenancy()) {
            navigate(pageToPath(page.Dashboard));
        } else {
            navigate(pageToPath(page.Tenants));
        }
    }, [])
    return null;
}

export function LoggedInAdminPanel() {
    const tableOption = useSelector<State>(state => state.table.option);
    const user = useSelector<State, User>(state => state.authentication.user);

    //If no tenant go to /tenants 

    const fallback = <div></div>

    if (!user.activeTenant) {
        return <div>
            <Suspense fallback={fallback}>
                <Navheader />
            </Suspense>
            <Suspense fallback={fallback}>
                <AddModal listSelector={tableOption} />
            </Suspense>
            <div className="content-wrapper flex tenant-poo">
                <Suspense fallback={fallback}>
                    <AlertWrapper />
                </Suspense>
                <Suspense fallback={fallback}>
                    <HelpPanelControl />
                </Suspense>
                <Routes>
                    <Route path="/*" element={<Suspense fallback={fallback}><TenantsPage /></Suspense>} />
                </Routes>
            </div>
            <div>
                <Suspense fallback={fallback}>
                    <UploadProgress />
                </Suspense>
            </div>
        </div>
    }

    return (
        <div>
            <Suspense fallback={fallback}>
                <Navheader />
            </Suspense>
            <Suspense fallback={fallback}>
                <AddModal listSelector={tableOption} />
            </Suspense>
            <div className="content-wrapper flex">
                <Suspense fallback={fallback}>
                    <AlertWrapper />
                </Suspense>
                <Suspense fallback={fallback}>
                    <HelpPanelControl />
                </Suspense>
                <Routes>
                    <Route path="/login" element={<AlreadyLoggedIn />} />
                    <Route path="/*" element={<Suspense fallback={fallback}><Dashboard /></Suspense>} />
                    <Route path={pageToPath(page.Tenants)} element={<Suspense fallback={fallback}><TenantsPage /></Suspense>} />
                    <Route path={pageToPath(page.Dashboard)} element={<Suspense fallback={fallback}><Dashboard /></Suspense>} />
                    <Route path={pageToPath(page.User)} element={<Suspense fallback={fallback}><TablePage page={page.User} /></Suspense>} />
                    <Route path="/profile" element={<Suspense fallback={fallback}><ProfilePage /></Suspense>} />
                    <Route path={pageToPath(page.Audit)} element={<Suspense fallback={fallback}><AuditLogs /></Suspense>} />
                    <Route path={pageToPath(page.Journey)} element={<Suspense fallback={fallback}><TablePage page={page.Journey} /></Suspense>} />
                    <Route path={pageToPath(page.Product)} element={<Suspense fallback={fallback}><TabsView selectedTab={page.Product} tabs={[page.Product, page.ProductType, page.Fulfilment, page.Brand]} /></Suspense>} />
                    <Route path={pageToPath(page.SectionTemplate)} element={<Suspense fallback={fallback}><TablePage page={page.SectionTemplate} /></Suspense>} />
                    <Route path={pageToPath(page.Tags)} element={<Suspense fallback={fallback}><TablePage page={page.Tags} /></Suspense>} />
                    <Route path={pageToPath(page.Appointments)} element={<Suspense fallback={fallback}><Appointments /></Suspense>} />
                    <Route path={pageToPath(page.Messages)} element={<Suspense fallback={fallback}><Messages /></Suspense>} />
                    {/* Tabs */}
                    <Route path={pageToPath(page.ProductType)} element={<Suspense fallback={fallback}><TabsView selectedTab={page.ProductType} tabs={[page.Product, page.ProductType, page.Fulfilment, page.Brand]} /></Suspense>} />
                    <Route path={pageToPath(page.Fulfilment)} element={<Suspense fallback={fallback}><TabsView selectedTab={page.Fulfilment} tabs={[page.Product, page.ProductType, page.Fulfilment, page.Brand]} /></Suspense>} />
                    <Route path={pageToPath(page.Brand)} element={<Suspense fallback={fallback}><TabsView selectedTab={page.Brand} tabs={[page.Product, page.ProductType, page.Fulfilment, page.Brand]} /></Suspense>} />
                    <Route path={pageToPath(page.Media)} element={<Suspense fallback={fallback}><MediaView tab={page.Media} /></Suspense>} />
                    <Route path={pageToPath(page.Search)} element={<Suspense fallback={fallback}><MediaView tab={page.Search} /></Suspense>} />
                    <Route path="/media/:search" element={<Suspense fallback={fallback}><MediaView tab={page.Search} /></Suspense>} />
                    <Route path={pageToPath(page.AssetSource)} element={<Suspense fallback={fallback}><MediaView tab={page.AssetSource} /></Suspense>} />
                    <Route path="/customers/:id" element={<Suspense fallback={fallback}><CustomerPage tab={page.CallsHistory} /></Suspense>} />
                    <Route path={`/customers/:id${pageToPath(page.VisitsHistory)}`} element={<Suspense fallback={fallback}><CustomerPage tab={page.VisitsHistory} /></Suspense>} />
                    <Route path={`/customers/:id${pageToPath(page.CallsHistory)}`} element={<Suspense fallback={fallback}><CustomerPage tab={page.CallsHistory} /></Suspense>} />
                    <Route path={`/customers/:id${pageToPath(page.PurchaseHistory)}`} element={<Suspense fallback={fallback}><CustomerPage tab={page.PurchaseHistory} /></Suspense>} />
                    <Route path={`/customers/:id${pageToPath(page.SMS)}`} element={<Suspense fallback={fallback}><CustomerPage tab={page.SMS} /></Suspense>} />
                    <Route path={`/customers/:id${pageToPath(page.Notes)}`} element={<Suspense fallback={fallback}><CustomerPage tab={page.Notes} /></Suspense>} />
                    <Route path={`/customers/:id${pageToPath(page.JourneyMessages)}`} element={<Suspense fallback={fallback}><CustomerPage tab={page.JourneyMessages} /></Suspense>} />
                    <Route path={`/customers/:id${pageToPath(page.AddressBook)}`} element={<Suspense fallback={fallback}><CustomerPage tab={page.AddressBook} /></Suspense>} />
                    <Route path={`/customers/:id${pageToPath(page.Settings)}`} element={<Suspense fallback={fallback}><CustomerPage tab={page.Settings} /></Suspense>} />
                    <Route path={pageToPath(page.Reports)} element={<Suspense fallback={fallback}><ReportPage /></Suspense>} />
                    <Route path={`${pageToPath(page.Reports)}/:name`} element={<Suspense fallback={fallback}><ReportResultsPage /></Suspense>} />
                    <Route path={pageToPath(page.Calls)} element={<Suspense fallback={fallback}><CallsPage tab={page.ShowAndSellCalls} /></Suspense>} />
                    <Route path={pageToPath(page.LivestreamCalls)} element={<Suspense fallback={fallback}><CallsPage tab={page.LivestreamCalls} /></Suspense>} />
                    <Route path={pageToPath(page.ShowAndSellCalls)} element={<Suspense fallback={fallback}><CallsPage tab={page.ShowAndSellCalls} /></Suspense>} />
                    <Route path={pageToPath(page.OneToOneCalls)} element={<Suspense fallback={fallback}><CallsPage tab={page.OneToOneCalls} /></Suspense>} />
                    <Route path={pageToPath(page.Tenant)} element={<Suspense fallback={fallback}><CompanySettingTabsView tab={page.Tenant} /></Suspense>} />
                    <Route path={pageToPath(page.Account)} element={<Suspense fallback={fallback}><CompanySettingTabsView tab={page.Account} /></Suspense>} />
                    <Route path={pageToPath(page.Shop)} element={<Suspense fallback={fallback}><TabsView selectedTab={page.Shop} tabs={[page.Shop, page.Services]} /></Suspense>} />
                    <Route path={pageToPath(page.Services)} element={<Suspense fallback={fallback}><TabsView selectedTab={page.Services} tabs={[page.Shop, page.Services]} /></Suspense>} />
                    <Route path={pageToPath(page.Integrations)} element={<Suspense fallback={fallback}><CompanySettingTabsView tab={page.Integrations} /></Suspense>} />
                    <Route path={pageToPath(page.Terms)} element={<Suspense fallback={fallback}><CompanySettingTabsView tab={page.Terms} /></Suspense>} />
                    <Route path={pageToPath(page.EmailTemplates)} element={<Suspense fallback={fallback}><EmailTemplatesPage /></Suspense>} />
                    {/* Editing Views */}
                    <Route path="/users/:id" element={<Suspense fallback={fallback}><UserView /></Suspense>} />
                    <Route path="/section/:id" element={<Suspense fallback={fallback}><SectionView /></Suspense>} />
                    <Route path="/section-templates/:id" element={<Suspense fallback={fallback}><SectionTemplateView /></Suspense>} />
                    <Route path="/products/:id" element={<Suspense fallback={fallback}><ProductView /></Suspense>} />
                    <Route path="/journey/:id" element={<Suspense fallback={fallback}><JourneyView /></Suspense>} />
                    <Route path="/assets/:id" element={<Suspense fallback={fallback}><AssetView /></Suspense>} />
                    <Route path="/asset-sources/:id" element={<Suspense fallback={fallback}><AssetSourceView /></Suspense>} />
                    <Route path="/brands/:id" element={<Suspense fallback={fallback}><BrandView /></Suspense>} />
                    <Route path="/fulfilment/:id" element={<Suspense fallback={fallback}><FulfilmentsPage /></Suspense>} />
                    <Route path="/product-types/:id" element={<Suspense fallback={fallback}><ProductTypesPage /></Suspense>} />
                    <Route path="/shops/:id" element={<Suspense fallback={fallback}><ShopLocationView /></Suspense>} />
                    <Route path="/orders/:id" element={<Suspense fallback={fallback}><OrderView /></Suspense>} />
                    <Route path="/crm" element={<Suspense fallback={fallback}><TablePage page={page.Customer} /></Suspense>} />
                    <Route path={pageToPath(page.Orders)} element={<Suspense fallback={fallback}><PageHost activeTab={page.Orders} tabs={[page.Orders, page.Consignment]} /></Suspense>} />
                    <Route path={pageToPath(page.Consignment)} element={<Suspense fallback={fallback}><PageHost activeTab={page.Consignment} tabs={[page.Orders, page.Consignment]} /></Suspense>} />
                    <Route path="/livestream-call/:id" element={<Suspense fallback={fallback}><LivestreamCallPage /></Suspense>} />
                    <Route path="/show-and-sell-call/:id" element={<Suspense fallback={fallback}><ShowAndSellCallPage /></Suspense>} />
                    <Route path="/:page" element={<Suspense fallback={fallback}><ThirdPartyPage /></Suspense>} />
                </Routes>
            </div>
            <div>
                <Suspense fallback={fallback}>
                    <UploadProgress />
                </Suspense>
                <Suspense fallback={fallback}>
                    <CSVUploadProgress />
                </Suspense>
            </div>
        </div>)
}
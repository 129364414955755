import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store'
import { apiService } from './api.service';

export const tenantService = {
    searchTenants,
    deleteTenant,
    updateTenant,
    addTenant,
    getTenantById,
    getAccountUsage,
    getHost
}

function getTenantById(id, includes) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    var url = `${config.apiUrl}/tenants/${id}?`;
    if (includes) {
        url+= `include=${includes}`
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(tenants => {
            return tenants
        })
}

function getHost(host) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/tenants/home?s=${host}`, requestOptions)
        .then(handleResponse)
        .then(tenants => {
            return tenants
        })
}

function searchTenants(options) {
    return apiService.getItems('tenants', options).then(tenants => tenants)
}

function deleteTenant(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/tenants/${id}`, requestOptions)
        .then(handleResponse);
}

function getAccountUsage(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/tenants/${id}/account`, requestOptions)
        .then(handleResponse);
}

function addTenant(tenant) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(tenant)
    }
    return fetch(`${config.apiUrl}/tenants`, requestOptions)
        .then(handleResponse)
        .then(tenant => { return tenant })
}

function updateTenant(tenant) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(tenant)
    }
    return fetch(`${config.apiUrl}/tenants`, requestOptions)
        .then(handleResponse)
}
import React, { useEffect, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import '../../../Styles/dashboard.scss';
import { journeyService } from "../../../_services/journeys.service";
import { AppointmentStatus } from '../../../_enums/AppointmentStatus';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';

export default function StatisticsCard() {
    const [inStoreStats, setInStoreStats] = useState([]);
    const [virtualStats, setVirtualStats] = useState([]);
    const [inStoreStatsCancelled, setInStoreStatsCancelled] = useState([]);
    const [virtualStatsCancelled, setVirtualStatsCancelled] = useState([]);
    const currentUserId = useSelector(state => state.authentication.user.id);
    const currentUserName = useSelector(state => state.authentication.user.name);
    const { t } = useTranslation("common");

    useEffect(() => {
        const options = {
            searchFields: [],
            searchValues: [],
            pageSize: 1000,
            include: ["videocall.participants", "staff"]
        };

        journeyService.getAppointments(options)
            .then(response => {
                processStatistics(response.items);
            });
    }, [currentUserId]);

    const processStatistics = (appointments) => {
        const userAppointments = appointments.filter(app => app.staff.some(s => s.userId === currentUserId));
        const globalAppointments = appointments;

        const inStore = {
            userConfirmed: userAppointments.filter(app => !app.videoCallId && app.status !== AppointmentStatus.Cancelled).length,
            globalConfirmed: globalAppointments.filter(app => !app.videoCallId && app.status !== AppointmentStatus.Cancelled).length,
            userCancelled: userAppointments.filter(app => !app.videoCallId && app.status === AppointmentStatus.Cancelled).length,
            globalCancelled: globalAppointments.filter(app => !app.videoCallId && app.status === AppointmentStatus.Cancelled).length,
        };
        const virtual = {
            userConfirmed: userAppointments.filter(app => app.videoCallId && app.status !== AppointmentStatus.Cancelled).length,
            globalConfirmed: globalAppointments.filter(app => app.videoCallId && app.status !== AppointmentStatus.Cancelled).length,
            userCancelled: userAppointments.filter(app => app.videoCallId && app.status === AppointmentStatus.Cancelled).length,
            globalCancelled: globalAppointments.filter(app => app.videoCallId && app.status === AppointmentStatus.Cancelled).length,
        };

        setInStoreStats([
            { name: 'Confirmed In-Store', User: inStore.userConfirmed, Global: inStore.globalConfirmed }
        ]);
        setInStoreStatsCancelled([
            { name: 'Cancelled In-Store', User: inStore.userCancelled, Global: inStore.globalCancelled }
        ])
        setVirtualStats([
            { name: 'Confirmed Virtual', User: virtual.userConfirmed, Global: virtual.globalConfirmed }
        ]);
        setVirtualStatsCancelled([
            { name: 'Cancelled Virtual', User: virtual.userCancelled, Global: virtual.globalCancelled }
        ]);
    };

    return (
        <Tabs defaultActiveKey="inStore" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="inStore" title={t("dashboard.in-store")}>
                <Card className='dashboard-statistics-card'>
                    <Card.Body className='group-charts'>
                        <ResponsiveContainer width="100%" height={180} className={"inStoreStats"}>
                            <BarChart data={inStoreStats} width={200} height={180}>
                                <XAxis dataKey="name" />
                                <Tooltip />
                                <Bar radius={[10, 10, 0, 0]} dataKey="User" fill="#61C4FF" name="userConfirmed" stackId="a" />
                                <Bar radius={[10, 10, 0, 0]} dataKey="Global" fill="#F40E6F" name="globalConfirmed" stackId="b" />
                            </BarChart>
                        </ResponsiveContainer>
                        <ResponsiveContainer width="100%" height={180} className={"inStoreStatsCancelled"}>
                            <BarChart data={inStoreStatsCancelled} width={200} height={180}>
                                <XAxis dataKey="name" />
                                <Tooltip />
                                <Bar radius={[10, 10, 0, 0]} dataKey="User" fill="#61C4FF" name="User" stackId="a" />
                                <Bar radius={[10, 10, 0, 0]} dataKey="Global" fill="#F40E6F" name="Global" stackId="b" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Card.Body>
                    <div className='small-index'>
                        <span className='personal-stats'><span className='personal-colour'></span>{currentUserName}</span><span className='global-stats'><span className='global-colour'></span>{t("dashboard.global")}</span>
                    </div>
                </Card>
            </Tab>
            <Tab eventKey="virtual" title={t("dashboard.virtual")}>
                <Card className='dashboard-statistics-card'>
                    <Card.Body className='group-charts'>
                        <ResponsiveContainer width="100%" height={180} className={"virtualStats"}>
                            <BarChart data={virtualStats} width={200} height={180}>
                                <XAxis dataKey="name" />
                                <Tooltip />
                                <Bar radius={[10, 10, 0, 0]} dataKey="User" fill="#61C4FF" name="userConfirmed" stackId="a" />
                                <Bar radius={[10, 10, 0, 0]} dataKey="Global" fill="#F40E6F" name="globalConfirmed" stackId="b" />
                            </BarChart>
                        </ResponsiveContainer>
                        <ResponsiveContainer width="100%" height={180} className={"virtualStatsCancelled"}>
                            <BarChart data={virtualStatsCancelled} width={200} height={180}>
                                <XAxis dataKey="name" color='#F40E6F' />
                                <Tooltip />
                                <Bar radius={[10, 10, 0, 0]} dataKey="User" fill="#61C4FF" name="User" stackId="a" />
                                <Bar radius={[10, 10, 0, 0]} dataKey="Global" fill="#F40E6F" name="Global" stackId="b" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Card.Body>
                    <div className='small-index'>
                        <span className='personal-stats'><span className='personal-colour'></span>{currentUserName}</span><span className='global-stats'><span className='global-colour'></span>{t("dashboard.global")}</span>
                    </div>
                </Card>
            </Tab>
        </Tabs>
    );
}

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import '../../Styles/_components/_login.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function LoginForm(props) {
    const { t } = useTranslation("common");
    const auth = useSelector(state => state.authentication);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({
        email: '',
        password: '',
        rememberMe: false
    });

    const [submitted, setSubmitted] = useState(false);
    const { email, password, rememberMe } = inputs;

    function login(e) {
        e.preventDefault();
        setSubmitted(true);
        if (email && password) {
            dispatch(userActions.login(email, password, props.subdomain, rememberMe, navigate));
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function toggleRememberMe() {
        setInputs(inputs => ({ ...inputs, rememberMe: !rememberMe }));
    }

    return <div className="login-form">
        <div className="login-logo">
            <div className='welcome'>{t("login.login-title")}</div>
            <div className='welcome-msg'>{t("login.login-subtitle")}</div>
        </div> <form name="form" onSubmit={login}>
            <div className="form-group">
                <label>{t("login.email")}</label>
                <input data-testid="email" type="text" name="email" value={email} onChange={handleChange} className={'form-control' + (submitted && !email ? ' is-invalid' : '')} />
            </div>
            <div className="form-group">
                <label>{t("login.password")}</label>
                <input data-testid="password" type="password" name="password" value={password} onChange={handleChange} className={'form-control' + (submitted && !password ? ' is-invalid' : '')} />
            </div>
            <div className="form-group">
                <input type="checkbox" value={rememberMe} className="rememberMe-input" placeholder={t("login.remember-me")} onChange={toggleRememberMe} />
                <label htmlFor="rememberMe" className='remember-label'>{t("login.remember-me")}</label>
            </div>
            <button data-testid="login" className="btn btn-primary">
                {(auth && auth.loggingIn) && <span className="spinner-border spinner-border-sm mr-1"></span>}
                {t("login.enter")}
            </button>
            <Link className='reset' onClick={() => props.setSection("reset")}>{t("login.reset-password")}</Link>
        </form></div>;
}

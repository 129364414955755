import { tenantService } from '../_services'
import { alertActions, tableActions, userActions } from "../_actions";
import { page, pageToPath } from '../_constants';

export const tenantActions = {
    addTenant,
    deleteTenant,
    saveTenantUpdate
}

function addTenant(tenant) {
    return dispatch => {
        tenantService.addTenant(tenant)
            .then(
                tenant => {
                    dispatch(tableActions.addToTable(tenant));
                    dispatch(alertActions.success(`Successfully added company ${tenant.name}`));
                },
            );
    }
}
function deleteTenant(id) {
    return dispatch => {
        tenantService.deleteTenant(id)
            .then(
                () => {
                    dispatch(userActions.changeTenancy(null, { pathname: pageToPath(page.Tenants) }))
                    dispatch(alertActions.success(`Successfully deleted company ${id}`));
                },
            )
    }
}
function saveTenantUpdate(tenant) {
    return dispatch => {
        tenantService.updateTenant(tenant)
            .then(
                tenant => {
                    dispatch(alertActions.success(`Successfully updated ${tenant.name} company`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}
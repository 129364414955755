import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import '../../../Styles/dashboard.scss';
import { journeyService, RoomType } from "../../../_services/journeys.service";
import { apiService } from "../../../_services/api.service";
import { formatDateAndTime, formatISOTime } from '../../../_helpers/utility';
import { AppointmentStatus } from '../../../_enums/AppointmentStatus';
import { useTranslation } from 'react-i18next';
import StatisticsCard from './StatisticsCard';

export default function TodaysAppointmentsCard() {
    const { t } = useTranslation("common");

    const [calls, setCalls] = useState([]);
    const [departments, setDepartments] = useState({});
    const [view, setView] = useState('upcoming');
    const currentUserId = useSelector(state => state.authentication.user.id);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 7);
    const startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        today.getHours()
    );
    const endDate = new Date(
        tomorrow.getFullYear(),
        tomorrow.getMonth(),
        tomorrow.getDate()
    );
    useEffect(() => {
        const options = { searchFields: ['start>', 'end<', 'roomType', 'staff.userId'], searchValues: [startDate.toISOString(), endDate.toISOString(), RoomType.ShowAndSell, currentUserId], sortBy: 'start', pageSize: 10 }
        journeyService.getAppointments(options).then(calls => {
            setCalls(calls.items);
            fetchDepartments(calls.items);
        })
    }, [])

    const fetchDepartments = async (calls) => {
        const departmentIds = calls.map(call => call.departmentId).filter(id => id);
        const uniqueDepartmentIds = [...new Set(departmentIds)];
        const departmentData = {};

        for (let id of uniqueDepartmentIds) {
            const department = await apiService.getItem("users", currentUserId, "departments", id);
            departmentData[id] = department.name;
        }

        setDepartments(departmentData);
    }

    function Call(props) {

        function formatTime(c) {
            console.log(c);
            if (new Date(c).getDate() == new Date().getDate()) {
                return formatISOTime(c);
            } else {
                return formatDateAndTime(c);
            }
        }

        const { c } = props;
        return <div className='appointment-booked-card'>
            <div className='appointment-booked-card-day'>{c.start ? formatTime(c.start) : undefined}<span className='sep-inline'>-</span>{c.end ? formatISOTime(c.end) : undefined}</div>
            <div className='appointment-booked-card-name'>{c.title ? c.title : 'No Name Room'}</div>
            <div className='appointment-booked-card-date'>{c.start ? formatTime(c.start) : undefined}<span className='sep-inline'>-</span>{c.end ? formatISOTime(c.end) : undefined}</div>
            <div className='appointment-booked-card-department'>
                <div><i className="far fa-building"></i> {departments[c.departmentId] ? departments[c.departmentId] : 'Loading...'}</div>
                <div><i className="far fa-store-alt"></i>{c.videoCallId ? "Store: Virtual" : "Store: In-Store"}</div>
            </div>
        </div>
    }

    function Calls(props) {
        const { calls } = props;

        if (!calls) return null;
        return calls.map((c, i) => {
            if (c.videoCallId) {
                if (c.status === AppointmentStatus.Cancelled) {
                    return (
                        <div key={i} className='cancelled-appointment'>
                            <a href={`show-and-sell-call/${c.videoCallId}`}  >
                                <span className='cancel-line' /><Call c={c} />
                            </a>
                        </div>
                    )
                } else {
                    return (
                        <div key={i} className='appointment-card-wrap'>
                            <a key={i} href={`show-and-sell-call/${c.videoCallId}`} >
                                <Call c={c} />
                            </a>
                        </div>

                    )
                }
            } else {
                if (c.status === AppointmentStatus.Cancelled) {
                    return (
                        <div key={i} className='cancelled-appointment'>
                            <span className='cancel-line' /><Call c={c} />
                        </div>
                    )
                } else {
                    return (
                        <div key={i} className='appointment-card-wrap'>
                            <Call key={i} c={c} />
                        </div>
                    )
                }
            }
        })
    }

    return (
        <div className='card-holder h-100'>
            <Card className='dashboard-appointments-card h-100'>
                <div className='dashboard-card-sm-title'>{t("dashboard.upcoming-appointments")}</div>
                <div className='dashboard-card-tags-wrap'>
                    <div className={`dashboard-card-tag ${view === 'upcoming' ? 'active' : ''}`} onClick={() => setView('upcoming')}>
                        {t("dashboard.upcoming")}
                    </div>
                    <div className={`dashboard-card-tag ${view === 'statistics' ? 'active' : ''}`} onClick={() => setView('statistics')}>
                        {t("dashboard.statistics")}
                    </div>
                </div>
                <Card.Body>
                    {view === 'upcoming' ? (
                        calls && calls.length ? <Calls calls={calls} className='appointments-calls' /> : <div>{t("dashboard.no-appointments-at-the-moment")}</div>
                    ) : (
                        <StatisticsCard calls={calls} />
                    )}
                </Card.Body>
            </Card>
        </div>
    )
}

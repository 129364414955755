import { assetsService } from "../_services";
import { customerService } from "../_services/customer.service";
import { alertActions } from "./alert.actions";
import { tableActions } from "./table.actions";

export const customerActions = {
    add,
    save,
    _delete,
    updateCustomerWithPhoto
}

function add(customer) {
    return dispatch => {
        customerService.add(customer)
            .then(
                customer => {
                    if (customer) {
                        dispatch(tableActions.addToTable(customer));
                        dispatch(alertActions.success(`${customer.name} successfully created`));
                    }
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}

function updateCustomerWithPhoto(file, customer, setCustomer) {
    return dispatch => {
        assetsService.uploadFile({ file: file })
            .then(
                img => {
                    customer.assetId = img.id;
                    let updatedCustomer = customer;
                    dispatch(save(updatedCustomer));
                    setCustomer((customer) => ({ ...customer, photo: img }))
                }
            )
    }
}

function _delete(id) {
    return dispatch => {
        customerService.remove(id)
            .then(
                () => {
                    dispatch(tableActions.removeFromTable(id));
                    dispatch(alertActions.success(`Successfully deleted customer ${id}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}

function save(customer, getCustomer) {
    return dispatch => {
        customerService.update(customer)
            .then(
                customer => {
                    dispatch(alertActions.success(`Successfully updated ${customer.name}`));
                    if (getCustomer)
                        getCustomer(customer.id)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}
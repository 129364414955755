import { userConstants, alertConstants, config, tableConstants, pageToPath, page } from '../_constants';
import { assetsService, userService } from '../_services';
import { alertActions } from './alert.actions';
import { LoginModalActions } from './loginModal.actions';
import Cookies from 'js-cookie';
import { tableActions } from './table.actions';
import { setCookie } from '../_services/user.service';

export const userActions = {
    login,
    loginWithToken,
    logout,
    silentLogout,
    resetPassword,
    updatePassword,
    deleteUser,
    removeFromTenant,
    addUser,
    uploadProfileImage,
    saveUserUpdate,
    addUserWithPhoto,
    changeTenancy,
};

function changeTenancy(newTenant, redirect) {
    return dispatch => {
        userService.changeTenancy(newTenant?.id)
            .then(
                user => {
                    let newDest;
                    if (newTenant)
                        newDest = `https://${newTenant.subdomain}.${config.admin}`
                    else
                        newDest = `https://${config.admin}`
                    if (redirect)
                        if (redirect.search)
                            newDest += `${redirect.pathname}${redirect.search}&prl-t=${user.token}`;
                        else
                            newDest += `${redirect.pathname}?prl-t=${user.token}`;
                    else
                        newDest += `/switch?prl-t=${user.token}`;
                    console.log("Change Tenancy", newDest);
                    location.href = newDest;
                },
                error => {
                    dispatch(loginFailure(error.toString()));
                    dispatch(LoginModalActions.openModal());
                }
            );
    }
}


function loginWithToken(token, subdomain, path) {
    let user = {
        token: token,
    };
    if (user.token) {
        let userString = JSON.stringify(user);
        localStorage.setItem('user', userString);
    }
    else {
        return () => { };
    }
    return dispatch => {
        userService.me()
            .then(
                user => {
                    user.token = token;
                    localStorage.setItem('user', JSON.stringify(user));
                    setCookie().then(() => {
                        dispatch(loginSuccess(user));
                        if (subdomain) {
                            Cookies.set("subdomain", subdomain, { path: "/", domain: config.domain, secure: true });
                        }

                        let pendingRedirect = localStorage.getItem('redirect');
                        console.log("Login with token", pendingRedirect)
                        if (pendingRedirect) {
                            localStorage.removeItem('redirect');
                            window.location = pendingRedirect;
                        } else {
                            console.log("Login with token", path)
                            if (path == '/switch?') {
                                window.location = '/dashboard'
                            } else
                                window.location = path;
                        }
                    })
                },
                error => {
                    dispatch(loginFailure(error.toString()));
                    dispatch(LoginModalActions.openModal());
                }
            );
    };
}

function login(email, password, subdomain, rememberMe, navigate) {
    return dispatch => {
        dispatch(loginRequest({ email }));
        userService.login(email, password, subdomain, rememberMe)
            .then(
                user => {
                    dispatch(loginSuccess(user));
                    if (subdomain) {
                        Cookies.set("subdomain", subdomain, { path: "/", domain: config.domain, secure: true });
                    }

                    let pendingRedirect = localStorage.getItem('redirect');
                    if (pendingRedirect) {
                        localStorage.removeItem('redirect');
                        window.location = pendingRedirect;
                    } else if (subdomain) {
                        navigate(pageToPath(page.Dashboard));
                    } else {
                        navigate(pageToPath(page.Tenants));
                    }
                },
                error => {
                    dispatch(loginFailure(error.toString()));
                    dispatch(LoginModalActions.openModal());
                }
            );
    };
}

function logout(navigate) {
    userService.logout(true, navigate);
    return { type: userConstants.LOGOUT };
}

function deleteUser(id, navigate) {
    return dispatch => {
        userService.deleteUser(id)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully deleted this user.`));
                    dispatch(logout(navigate))
                },
                error => {
                    dispatch(alertActions.error('There was an error deleting the user. ' + error.toString()));
                });
    };
}

function removeFromTenant(row) {
    console.log(row)
    return dispatch => {
        userService.removeFromTenancy(row.id)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully removed ${row.name} from this company`));
                    dispatch({ type: tableConstants.REMOVE_FROM_TABLE, item: row })
                },
                error => {
                    dispatch(alertActions.error('There was an error deleting the user. ' + error.toString()));
                });
    }
}

function updatePassword(password, user) {
    return dispatch => {
        userService.updatePassword(password, user)
            .then(
                () => dispatch(updatePasswordSuccess('Your password has now been reset.')),
            );
    }
}

function resetPassword(email, navigate) {
    return dispatch => {
        userService.resetPassword(email)
            .then(() => dispatch(alertActions.success('Password reset instructions have been sent to ' + email)))
            .catch(rejected => dispatch(alertActions.error('Password reset failed ' + rejected)))
            .then(() => userService.logout(false, navigate))
    }
}

function addUser(user) {
    return dispatch => {
        userService.addUser(user)
            .then(
                user => {
                    if (user) {
                        dispatch(tableActions.addToTable(user));
                        dispatch(alertActions.success(`Successfully added user`));
                    }
                },
                error => {
                    dispatch(alertActions.error(`Unable to add user. ` + error.toString()));
                });
    }
}

function saveUserUpdate(user) {
    return dispatch => {
        if (user.newRole)
            userService.updateRole(user.id, user.newRole);
        userService.update(user)
            .then(
                user => {
                    dispatch(alertActions.success(`Successfully updated ${user.name}`));
                },
                error => {
                    dispatch(alertActions.error('There was an error updating the user. ' + error.toString()));
                }
            )
    }
}

function uploadProfileImage(file, user, setUser) {
    return dispatch => {
        assetsService.uploadFile({ file: file })
            .then(
                userImage => {
                    user.assetId = userImage.id;
                    let newUser = user;
                    setUser((user) => ({ ...user, photo: userImage }));
                    dispatch(alertActions.success('Successfully uploaded image.'));
                    return dispatch(saveUserUpdate(newUser));
                },
                error => {
                    dispatch(alertActions.error('There was an error uploading user image. ' + error.toString()));
                })
    }
}

function addUserWithPhoto(file, user, setUser) {
    return dispatch => {
        userService.update(user)
            .then(
                updateUser => {
                    dispatch(uploadProfileImage(file, updateUser, setUser));
                    dispatch(alertActions.success(`Successfully uploaded profile image`))
                },
            )
    }
}

function loginRequest(user) { return { type: userConstants.LOGIN_REQUEST, user } }
function loginSuccess(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
function loginFailure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
function silentLogout() { return { type: userConstants.LOGOUT }; }

function updatePasswordSuccess(message) { return { type: alertConstants.SUCCESS, message } }

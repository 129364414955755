import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store';
import { apiService } from './api.service';


export const userService = {
    login,
    logout,
    getById,
    get,
    update,
    deleteUser,
    updateRole,
    removeFromTenancy,
    updatePassword,
    resetPassword,
    getSortedUsers,
    accessCodePasswordReset,
    getStatus,
    addUser,
    changeTenancy,
    me,
    getBusyTimes
};

function getStatus() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${config.apiUrl}/status`, requestOptions)
        .then(handleResponse);
}

function addUser(user) {
    const emailTrim = user.email.trim()
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({ ...user, email: emailTrim })
    }
    return fetch(`${config.apiUrl}/users`, requestOptions)
        .then(handleResponse)
}

function changeTenancy(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/sessions/tenant/${id ?? "null"}`, requestOptions)
        .then(handleResponse)
        .then(error => { return error });
}

function removeFromTenancy(id) {
    return updateRole(id, 0)
}

function updateRole(userId, role) {
    return apiService.updateItem(`users/${userId}/set-role`, { role: role })
}

function deleteUser(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/users/${id}`, requestOptions)
        .then(handleResponse)
        .then(error => { return error });
}

function login(email, password, subdomain, rememberMe) {

    let loginRequest = { email, password, scope: { subdomain }, extended: rememberMe };
    if (!subdomain || (subdomain == "admin") || (subdomain == "dev-admin") || (subdomain == "local"))
        delete loginRequest.scope;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(loginRequest)
    };

    return fetch(`${config.apiUrl}/sessions`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user) {
                localStorage.setItem('user', JSON.stringify(user));
            } else {
                throw 401;
            }
            return setCookie().then(() => {
                return user;
            });
        }).then(error => { return error })
}

export const setCookie = () => {
    const requestOptions = {
        method: 'GET',
        credentials: "include",
        headers: { ...authHeader(), 'Access-Control-Allow-Origin': '*' }
    }
    return fetch(`${config.assetUrl}/set-cookie`, requestOptions)
}

function me() {
    const requestOptions = {
        method: 'GET',
        credentials: "include",
        headers: { ...authHeader(), 'Access-Control-Allow-Origin': '*' }
    }
    return fetch(`${config.apiUrl}/users/me`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        }).then(error => { return error })
}

function logout(redirectToLogin, navigate) {

    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/sessions`, requestOptions)
        .then(() => {
            localStorage.removeItem('user');
            if (redirectToLogin)
                navigate("/login");
        }).catch(() => {
            localStorage.removeItem('user');
            if (redirectToLogin)
                navigate("/login");
        });


}

function resetPassword(email, errorHandled) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        body: JSON.stringify({ email })
    }

    var thing = fetch(`${config.apiUrl}/users/reset-password`, requestOptions)
        .then(r => handleResponse(r, errorHandled));

        return thing;
}

function accessCodePasswordReset(accessCode, password) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({ accessCode })
    };
    return fetch(`${config.apiUrl}/sessions`, requestOptions)
        .then(handleResponse)
        .then(
            user => {
                if (user) {
                    localStorage.setItem('user', JSON.stringify(user));
                    setNewPassword(password, user)
                }
                return user;
            },
            error => {
                console.log(error)
            });
}

function setNewPassword(password, user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        body: JSON.stringify({ password: password })
    };

    return fetch(`${config.apiUrl}/users/${user.id}/set-password`, requestOptions)
        .then(handleResponse)
}

function updatePassword(password, user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        body: JSON.stringify({ password })
    };

    return fetch(`${config.apiUrl}/users/${user.id}/set-password`, requestOptions)
        .then(handleResponse)
        .then(user => { return user },
            error => { return error })

}


function getById(id, options) {
    return apiService.getItem('users', id, options)
}

function get(options) {
    return apiService.getItems('users', options)
}

function getSortedUsers(key, dir, page, pageSize) {
    return get({ sortBy: key, sortDirection: dir, page: page, pageSize: pageSize })
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        body: JSON.stringify({ ...user, email: user.email.trim() })
    };

    return fetch(`${config.apiUrl}/users/`, requestOptions)
        .then(handleResponse)
}

function getBusyTimes(id, start, end) {
    return apiService.getItem(`users/${id}/busy?start=${start}&end=${end}`)
}
import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store'
import { generateOptions } from './options';

export const apiService = {
    getItem,
    getItems,
    addItem,
    updateItem,
    deleteItem,
}

function headers() {
    return {
        ...authHeader(),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
}

function getItem(path, id, options) {
    const requestOptions = {
        method: 'GET',
        headers: headers()
    };
    let url = `${config.apiUrl}/${path}`;
    if (id)
        url += `/${id}`;

    if (options) {
        url += generateOptions(url, options);
    }
    if (options && options.handled)
        return fetch(url, requestOptions)
    else
        return fetch(url, requestOptions)
            .then(handleResponse)
}

function getItems(path, options) {
    const requestOptions = {
        method: 'GET',
        headers: headers()
    };
    let url = `${config.apiUrl}/${path}`;
    if (options) {
        url += generateOptions(url, options);
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
}

function addItem(path, object, handled = false) {
    const requestOptions = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(object)
    };
    let url = `${config.apiUrl}/${path}`;
    return fetch(url, requestOptions)
        .then((res) => handleResponse(res, handled))
}

function updateItem(path, object) {
    const requestOptions = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(object)
    };
    let url = `${config.apiUrl}/${path}`;
    return fetch(url, requestOptions)
        .then(handleResponse)
}

function deleteItem(path, id) {
    const requestOptions = {
        method: 'DELETE',
        headers: headers(),
    };
    let url = `${config.apiUrl}/${path}/${id}`;
    return fetch(url, requestOptions)
        .then(handleResponse)
} 
